export default {
  CUSTOMERS_LIST: "Liste des clients",
  ADD_CUSTOMER: "Ajouter un client",
  CUSTOMER_DETAILS: "Détails cient",
  CUSTOMER_ADDED: "Client ajouté",
  EDIT_CUSTOMER: "Modifier un client",
  CUSTOMER_UPDATED: "Client mis à jour",
  DELETE_THIS_CUSTOMER: "Voulez-vous supprimer ce client sélectionné ?",
  DELETE_CUSTOMER_SUCCESS: "La suppression du client a été effectuée avec succès",
  CUSTOMER_DELETED: "Client supprimé",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Même adresse que le client",
  CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  CUSTOMER_TYPE_COMPANY: "Entreprise",
  VIEW_CUSTOMER: "Client",
  LAST_NAME: "Nom du client",
  FIRST_NAME: "Prénom du client",
  EMAIL: "Courriel du client",
  PHONE: "Numéro de téléphone du client",
  COUNTRY: "Pays de résidence",
  ADDRESS: "Adresses du client",
  CUSTOMER_DETAILS: "Détails client",
  NATIONALITY: "Nationalité",
  REGION: "Région",
  ZIP_CODE: "Code postal",
  HISTORY: "Historique",
  CUSTOMER_INFORMATION: "Information sur le client",
  BIRTHDAY: "Date de naissance",
  SEX: "Sexe",
  RESERVATION_NUMBER: "N° de réservation",
  STAY: "Séjour",
  PAYMENT_MODE: "Mode de paiement",
  PAYMENT_METHOD: "Moyen de paiement",
  CREDIT_CARD: "Carte bancaire",
  ADD_PAYMENT_INFORMATION: "Ajouter une information de paiement",
  OPTIONAL: "Facultatif",
  CARD_OWNER: "Nom du titulaire de la carte",
  NO_DATA: "Vous n'avez ajouté aucun client pour le moment, cliquer sur le bouton pour ajouter.",
};
