import {
  ALLOWED_CUSTOMER_EQUIPMENT,
  ANY,
  MOTORHOME,
  TENT,
  TENT_TRAILER,
  TRAILER,
  VAN,
} from "../../constants/common";

export default {
  SPOT_LIST: "Liste des emplacements",
  ADD_SPOT: "Ajouter un emplacement",
  SPOT_ADDED: "Emplacement ajouté",
  EDIT_SPOT: "Modifier l'emplacement",
  SPOT_UPDATED: "Emplacement mis à jour",
  DELETE_THIS_SPOT: "Voulez-vous supprimer l’ emplacement sélectionné ?",
  DELETE_SPOT_SUCCESS:
    "La suppression de l'emplacement a été effectuée avec succès",
  SPOT_DELETED: "Emplacement supprimé",
  SPOT_GENERAL_INFORMATION: "Informations générales",
  SPOT_SPECIFIC_INFORMATION: "Informations spécifiques",
  SPOT_CAPACITY: "Capacité",
  SPOT_PET_CAPACITY: "Animaux",
  SPOT_PETS_CAPACITY: "Nombre d'animaux",
  SPOT_CHILDREN_CAPACITY: "Nombre d'enfants",
  SPOT_ADULT_CAPACITY: "Nombre d'adultes",
  SPOT_NAME: "Nom de l'emplacement",
  SPOT_TYPE: "Type d'emplacement",
  SPOT_NUMBER: "N° d'identification",
  SPOT_EQUIPMENTS: "Équipements",
  SPOT_SHEET: "Fiche camping",
  SPOT_LENGTH: "Longueur de l'emplacement",
  SPOT_SCHEDULE: "Horaire",
  SPOT_ARRIVAL_TIME: "Heure d'arrivée",
  SPOT_DEPARTURE_TIME: "Heure de départ",
  SPOT_INCLUDED_EQUIPMENT: "Équipement inclus",
  SPOT_INCLUDED_EQUIPMENT_FOR: "Équipement inclus pour",
  SPOT_GALLERY: "Images",
  SPOT_MAIN_IMAGE: "Image principale",
  SPOT_OTHER_IMAGE: "Autres images",
  SPOT_STATUS_AVAILABLE: "Disponible",
  SPOT_STATUS_NOT_AVAILABLE: "Non disponible",
  SPOT_STATUS_PARTIALY_AVAILABLE: "Partiellement disponible",
  CLEAN_CONDITION: "État",
  SPOT_CONDITION_CLEAN: "Propre",
  SPOT_CONDITION_DIRTY: "Sale",
  SPOT_LENGTH_FEET: "pieds",
  EQUIPMENT_LENGTH: "Longueur de l'équipement",
  SPOT_AREA: "Superficie du terrain ",
  PASSENGER_EXTENSIONS: "Nombre d'extensions côté passager",
  DRIVER_EXTENSIONS: "Nombre d'extensions côté conducteur",
  ALLOWED_EQUIPMENT: "Équipement de camping permis",
  ALLOWED_EQUIPMENT_ANY: "Tous",
  ALLOWED_EQUIPMENT_TENT: "Tente",
  ALLOWED_EQUIPMENT_TENT_TRAILER: "Tente-roulotte",
  ALLOWED_EQUIPMENT_TRAILER: "Roulotte",
  ALLOWED_EQUIPMENT_VAN: "Van",
  ALLOWED_EQUIPMENT_MOTORHOME: "Motorisé",
  BEDS_KING_QTY: "Quantité de lits king",
  BEDS_QUEEN_QTY: "Quantité de lits queen",
  BEDS_DOUBLE_QTY: "Quantité de lit double",
  BEDS_TWIN_QTY: "Quantité de lit jumeau",
  BEDS_TOTAL_QTY: "Quantité de lit total",
  BATHROOM_SHOWER_QTY: "Quantité de salle de bain avec douche",
  BATHROOM_BATHTUB_QTY: "Quantité de salle de bain avec baignoire",
  SAME_SPOTS:
    'Un ou plusieurs emplacements ont déjà été enregistrés avec le nom {name}. S\'agit-il du même emplacement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA:
    "Vous n’avez ajouté aucun emplacement pour le moment, cliquer sur le bouton pour ajouter.",
  SPOT_DETAILS: "Détail d'un emplacement",
  STATUS_DRAFT: "Brouillon",
  STATUS_ACTIVE: "Active",
  STATUS_INACTIVE: "Inactive",
  CHANGE_RATES: "Changer de tarifs",
  EQUIPMENT_SELECTION: "Choix de l’équipement",
  MAX_LENGTH: "Longueur max {length} pieds",
  PASSENGER_EXTENSION: "Extension conducteur",
  DRIVER_EXTENSION: "Extension passager",
  SERVICES_INCLUDED: "Services inclus",
  ELECTRICITY: "Électricité",
  KILOWATT: "Kilowatt mesuré",
  KILOWATT_DATE: "Date de mesure du Kilowatt",
  INTERNAL_NOTE: "Note interne",
  OPTIONS: "Options",
  ROOMS_QTY: "Nombre de chambres",
  BEDS: "Lits",
  BATHROOMS: "Salles de bain",
  DUPLICATE_SPOTS: "Copier",
  NUMBER_OF_COPIES: "Nombre de copies",
  COPYING: "Copie en cours",
  OUT_OF_SERVICE_REASON: "Raison de la mise hors service",
  MAP_POINT_SIZE: "Taille du point",
  SHOW_HELPER: "Afficher le contour",
  SHOW_CODES: "Afficher les codes",
  BACK_INTO_SERVICE: "Remettre en service",
  SET_SPOT_POINT_ON_MAP: "Definir la position de l'emplacement sur la carte",
  PUT_BACK_INTO_SERVICE_THIS_SPOT:
    "Voulez vous vraiment remettre en service cet emplacement entre la période du {start} au {end} ?",
  CLEAN_SPOT: "Mettre au propre",
  CLEAN_THIS_SPOT: "Voulez-vous vraiment mettre cet emplacement au propre ?",
  SPOT_CLEANED: "Emplacement mis au propre",
  SUM_OF_CAPACITY_DOES_NOT_MATCH_X_CAPACITY_X_ADULT_X_CHILDREN: "La somme du nombre d'adulte {adults_capacity} et du nombre d'enfants {children_capacity} ne correspond pas a la capcite totale {capacity}",
  SET_SPOT_POSITION: "Définir la position de l'emplacement",
  THE_SPOT_MUST_BE_PUT_ON_MAP: "L'emplacement doit être défini sur la carte",
  NO_PRICING_FOUND_FOR_SPOT: "Aucun prix trouvé pour l'emplacement",
};
